<template>
  <div class="container-fluid bg-light py-3 min-vh-75">
    <main class="container-xl">
        <h1>Respaldos en la Nube</h1>
        <div class="row gx-0 mt-5 mb-lg-5 my-0 justify-content-sm-center">
          <p class="mt-4 fs-5">Un proceso automático que respalda tus archivos en la nube de Microsoft Azure, 
            al cual se le configura la frecuencia y horario para que puedas continuar sin interrupciones tus operaciones del día</p>
      </div>
      <div class="row gx-0 mt-5 mb-lg-5 my-0 justify-content-sm-center">
        <p class="fs-5">Así en caso de que se presente la perdida de tu información, ya sea a causa de algún fallo en el hardware o por software malicioso, 
            tendrás la oportunidad de restaurarla desde la nube</p>
          <p class="fst-italic">La velocidad con que se realizan los respaldos está limitada por tu conexión de internet</p>
      </div>
    </main>
    <div>
      <a href="#" @click.prevent="$emit('verFormDescarga',6)" data-bs-toggle="modal" data-bs-target="#Modal" class="btn btn-success fw-bold me-2">Agenda cita</a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'RespaldosNube'
}
</script>